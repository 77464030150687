import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
		meta: { public: true },
  },

	{
		path: '/dashboard',
    name: 'Dashboard',
		component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
		meta: { admin: true },
	},
	{
		path: '/user/cleaner',
    name: 'Cleaner',
		component: () => import(/* webpackChunkName: "Dashboard" */ '../views/user/Cleaner.vue'),
		meta: { admin: true },
	},
	{
		path: '/user/list',
		name: 'UsersList',
		component: () => import(/* webpackChunkName: "UserList" */ '../views/user/List.vue'),
		meta: { admin: true },
	},
	{
		path: '/evento/list',
		name: 'EventoList',
		component: () => import(/* webpackChunkName: "EventoList" */ '../views/evento/List.vue'),
		meta: { admin: true },
	},
	{
		path: "/user/edit/:id",
		name: "UserEdit",
		component: () => import(/* webpackChunkName: "UserEdit" */ "../views/user/Edit.vue"),
		meta: { admin: true },
	},
	{
		path: '/evento/new',
		name: 'EventoNew',
		component: () => import(/* webpackChunkName: "EventoNew" */ '../views/evento/New.vue'),
		meta: { admin: true },
	},
	{
		path: "/evento/edit/:id",
		name: "EventoEdit",
		component: () => import(/* webpackChunkName: "EventoEdit" */ "../views/evento/Edit.vue"),
		meta: { admin: true },
	},
	{
		path: "/inscripciones/archivo",
		name: "InscripcionArchivo",
		component: () => import(/* webpackChunkName: "InscripcionArchivo" */ "../views/inscripcion/Archive.vue"),
		meta: { admin: true },
	},
	{
		path: "/inscripciones/proximo",
		name: "InscripcionNext",
		component: () => import(/* webpackChunkName: "InscripcionNext" */ "../views/inscripcion/Next.vue"),
		meta: { admin: true },
	},
	{
		path: "/inscripciones/evento/:id",
		name: "InscripcionesNextEvento",
		component: () => import(/* webpackChunkName: "InscripcionNext" */ "../views/inscripcion/NextByEvent.vue"),
		meta: { admin: true },
	},
	{
		path: "/video/list",
		name: "VideoList",
		component: () => import(/* webpackChunkName: "VideoList" */ "../views/video/List.vue"),
		meta: { admin: true },
	},
	{
		path: "/calendario/list",
		name: "CalendarioList",
		component: () => import(/* webpackChunkName: "CalendarioList" */ "../views/calendario/List.vue"),
		meta: { admin: true },
	},
		{
		path: "/conectados/",
		name: "ReportesConectados",
		component: () => import(/* webpackChunkName: "InscripcionArchivo" */ "../views/reportes/Conectados.vue"),
		meta: { admin: true },
	},
	// {
	// 	path: "/video/edit/:id",
	// 	name: "VideoEdit",
	// 	component: () => import(/* webpackChunkName: "VideoEdit" */ "../views/video/Edit.vue"),
	// 	meta: { admin: true },
	// },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	store.dispatch("userModule/autoLogin");

	// if (store.state.userModule.user != null){
	// 	store.dispatch("userModule/loggedOut");
	// }

	if (to.matched.some(record => record.meta.public)) {

		if (store.state.userModule.user && store.state.userModule.user.role === 'STAFF') {
			next({ name: "Dashboard" });
		} else {
			next();
		}

	} else if (store.state.userModule.user && store.state.userModule.user.role === 'STAFF') {
		if (to.matched.some((record) => record.meta.admin)) {
			next();
		}
	} else {
		next({ name: 'Home' });
	}
});

export default router
